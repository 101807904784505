import React, { useEffect, useState } from 'react';
import CardContainer from '../../CardContainer';
import ButtonElement from '../../../components/button/button';
import FormCard from '../FormWizzard/FormCard';
import SelectInput from '../../../components/Input/SelectInput';
import InputField from '../../../components/Input/Input';
import FrFlagCircle from '../../../assets/icons/FrFlagCircle.svg';
import GridItems from '../../GridList/GridList';
import CheckBoxCounter from '../FormWizzard/CheckBoxCounter';
import checkCartType from '../../../utils/checkCartType';
import { createNewRedevance } from '../../../utils/createNewRedevance';
import { setEtablissementByIdFinancialInfos } from '../../../store/actions/actions';
import { useDispatch } from 'react-redux';
import { DeleteRedevanceDetails } from '../../../utils/DeleteRedevance';
import { UpdateRedevanceDetails } from '../../../utils/UpdateRedevance';

export interface IRedevance {
  id: string;
  isActive: boolean;
  isPrinciple: boolean;
  name: string;
  pct: number;
}

export default function InformationFinanciereEtablissement(props: any) {
  const [cardType, setCardType] = useState('unknown');
  const dispatch = useDispatch();

  useEffect(() => {
    const firstNumber = props.financialInfos?.card_number?.toString().charAt(0);

    setCardType(checkCartType(firstNumber));
  }, [props.financialInfos?.card_number]);

  const handleChangeOwner = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    props.setFormDataFinancial((prevState: any) => ({
      ...prevState,
      payload: {
        ...prevState.payload,
        owner: value,
      },
    }));
  };

  const handleChangePhone_number = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    props.setFormDataFinancial((prevState: any) => ({
      ...prevState,
      payload: {
        ...prevState.payload,
        owner_mobile_number: value,
      },
    }));
  };

  const handleChangeIban = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    props.setFormDataFinancial((prevState: any) => ({
      ...prevState,
      payload: {
        ...prevState.payload,
        iban: value,
      },
    }));
  };

  const handleChangeSwift_BIC = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    props.setFormDataFinancial((prevState: any) => ({
      ...prevState,
      payload: {
        ...prevState.payload,
        swift_BIC: value,
      },
    }));
  };

  const handleChangeCard_number = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = e.target;
    if (name == 'card_number') {
      const firstNumber = value?.charAt(0);

      setCardType(checkCartType(firstNumber));
    }

    props.setFormDataFinancial((prevState: any) => ({
      ...prevState,
      payload: {
        ...prevState.payload,
        card_number: value,
      },
    }));
  };
  // const onChangeRedevanceName = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { id, value, checked, name } = e.target;
  //   props.setFormDataFinancial((prevState: any) => ({
  //     ...prevState,
  //     payload: {
  //       ...prevState.payload,
  //       royaltiesInformation: {
  //         ...prevState.payload.royaltiesInformation,
  //         [id]: {
  //           ...prevState.payload.royaltiesInformation[id],
  //           [name]: value,
  //         },
  //       },
  //     },
  //   }));
  // };

  const handleChangeCounter = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value, checked, name } = event.target;
    //dispatch(setEditFormDirty(true));
    if (value === 'delete') {
      DeleteRedevanceDetails({
        formData: props.financialInfos,
        dispatch,
        id,
      });
    } else {
      UpdateRedevanceDetails({
        formData: props.financialInfos,
        dispatch,
        id,
        checked,
        value,
        name,
      });
    }
  };
  const addRedevanceDetails = (props: any) => {
    const { newFormData, dispatch } = props;
    if (
      newFormData.royalties !== undefined &&
      newFormData.royalties.length < 6
    ) {
      const updatedFormData: any = {
        ...newFormData,
        royalties: [...(newFormData.royalties || []), createNewRedevance()],
      };

      dispatch(setEtablissementByIdFinancialInfos(updatedFormData));
    } else {
      console.log('Cannot add more than 6 royaltiesInformation');
    }
  };
  return (
    <div className="FicheTab fl_col gp16">
      <CardContainer
        type="form"
        ClassName="fl_row jc_fe"
        collapse
        label="Informations compte bancaire"
      >
        <p className="p13">
          Réception de vos paiements. Vous pouvez choisir entre compte
          bancaire/IBAN ou numéro CB.
        </p>

        <FormCard grid={1} gap="gp20" fieldsetLabel="Compte Bancaire/IBAN">
          <GridItems grid={3} gap={20}>
            <InputField
              isRequired={true}
              type="text"
              name="Titulaire"
              label="Titulaire"
              value={props.formDataFinancial?.owner}
              placeholder={props.financialInfos?.owner}
              onChange={handleChangeOwner}
              isDisabled={
                props.claimEtablissementInformationFinancièreModification
                  ? false
                  : true
              }
              style="form"
            />

            <SelectInput
              label="Mobile"
              placeholder={props.financialInfos?.phone_number}
              onChange={handleChangePhone_number}
              isDisabled={
                props.claimEtablissementInformationFinancièreModification
                  ? false
                  : true
              }
              target="reflags"
              icons={
                [
                  // FrFlagCircle,
                  // 'https://cdn.britannica.com/55/1455-004-5897143C/Flag-Hungary.jpg',
                  // FrFlagCircle,
                  // FrFlagCircle,
                  // FrFlagCircle,
                  // FrFlagCircle,
                ]
              }
              reference={['01', '02', '03', '04', '05', '06', '07', '08', '09']}
              options={[
                'Option01',
                'Option02',
                'Option03',
                'Option0001',
                'OptionGG',
                'OptiDGDGGon03',
                'Option01',
                'OptioEn02',
                'OptioEEEGn03',
              ]}
              isRequired
            />
          </GridItems>
          <GridItems grid={2}>
            <InputField
              isRequired={true}
              type="text"
              name="IBAN"
              label="IBAN"
              value={props.formDataFinancial?.iban}
              placeholder={props.financialInfos?.iban}
              onChange={handleChangeIban}
              isDisabled={
                props.claimEtablissementInformationFinancièreModification
                  ? false
                  : true
              }
              style="form"
            />
            <InputField
              isRequired={true}
              type="text"
              name="swift_BIC"
              label="SWIFT/BIC"
              value={props.formDataFinancial?.swifT_BIC}
              placeholder={props.financialInfos?.swifT_BIC}
              onChange={handleChangeSwift_BIC}
              isDisabled={
                props.claimEtablissementInformationFinancièreModification
                  ? false
                  : true
              }
              style="form"
            />
            <FormCard grid={2} fieldsetLabel="Carte bancaire">
              <InputField
                isRequired={true}
                name="card_number"
                type="number"
                value={props.formDataFinancial?.card_number}
                placeholder={props.financialInfos?.card_number}
                label="Numéro de carte bancaire"
                onChange={handleChangeCard_number}
                style="card"
                isDisabled={
                  props.claimEtablissementInformationFinancièreModification
                    ? false
                    : true
                }
                cardType={cardType}
                maxLengthCardNumber={16}
                maxLength={16}
                className="haserror"
              />
            </FormCard>
          </GridItems>
        </FormCard>
      </CardContainer>

      <CardContainer label="Redevance" collapse type={'form'}>
        <FormCard grid={6} gap="gp24">
          {props.financialInfos?.royalties?.map(
            (item: IRedevance, index: number) => {
              return (
                <CheckBoxCounter
                  label={item.name}
                  name={item.name}
                  id={item.id}
                  prefix="%"
                  //ischecked={item.isActive}
                  onChangeCounter={handleChangeCounter}
                  initialCounter={item.pct}
                  key={''}
                  
                />
              );
            },
          )}
          {props.financialInfos?.royalties?.length &&
          props.financialInfos?.royalties?.length < 6 ? (
            <ButtonElement
              className="btn-repeater"
              type="IconButton"
              text={'add'}
              buttonSize="none"
              buttonStyle="fl_rev btn-grey-light"
              radius="rd8"
              textColor="GraniteGray-txt"
              onClick={() =>
                addRedevanceDetails({
                  newFormData: props.financialInfos,
                  dispatch,
                  key: 3,
                })
              }
            />
          ) : null}
        </FormCard>
      </CardContainer>
    </div>
  );
}
