import React, { useRef, useState } from 'react';
import SelectInput from '../../../../components/Input/SelectInput';
import { useSelector } from 'react-redux';
import { serviceEntrepriseModels } from '../../../../models/services/module.entreprise';
import { useDispatch } from 'react-redux';
import etablissementApi from '../../../../services/apiHandlers/etablissementApi';
import ButtonElement from '../../../../components/button/button';

export default function ChooseEtablissement({
  onConfirm,
}: {
  onConfirm?: () => void;
}) {
  const dispatch = useDispatch();
  const companies_list = useSelector(
    (state: { entreprise: serviceEntrepriseModels.EntrepriseState }) =>
      state.entreprise.entreprises,
  );
  const [idEtab, setidEtab] = useState<number | undefined>();
  const [idEntr, setidEntr] = useState<number | undefined>();
  const [Etablist, setEtablist] = useState([]);
  const ref = useRef<HTMLInputElement>(null);
  const fetchEtablissementslist = async () => {
    localStorage.setItem('idCompany', '' + idEntr);
    const response = await etablissementApi.getAllEtablissements({
      paginationModel: {
        page: 1,
        pageSize: 1000,
      },
      companyId: idEntr,
    });
    setEtablist(response?.data?.result?.establishements);
  };

  React.useEffect(() => {
    if (idEntr) {
      setEtablist([]);
      fetchEtablissementslist();
    }
  }, [idEntr]);
  React.useEffect(() => {
    if (idEtab) localStorage.setItem('idEtablishement', idEtab + '');
  }, [idEtab]);
  console.log({ idEtab });
  return (
    <div className="fl_col gp16">
      <div className="fl_col gp16">
        <SelectInput
          options={companies_list?.map((_) => _.commercial_Name)}
          placeholder={''}
          isRequired={false}
          label={'Entreprise'}
          name="entreprise"
          target="reference"
          onSelect={(selectValue) => {
            setidEntr(
              companies_list?.find((_) => _.commercial_Name == selectValue)?.id,
            );
          }}
        />
        <SelectInput
          defaultValue={
            (Etablist?.find((_: any) => _?.id == idEtab) as any)
              ?.commercial_Name ?? ''
          }
          options={Etablist?.map((_: any) => _?.commercial_Name)}
          placeholder={''}
          isRequired={false}
          label={'établissement'}
          name="établissement"
          // onSelect={onSelectNaf}
          target="reference"
          onSelect={(selectValue) => {
            setidEtab(
              (
                Etablist?.find(
                  (_: any) => _?.commercial_Name == selectValue,
                ) as any
              )?.id,
            );
          }}
        />
      </div>
      <ButtonElement
        className="w-full"
        type="TextButton"
        textSize="p18"
        text="Accéder"
        textColor="white-txt"
        buttonSize="full"
        radius="rd12"
        onClick={idEntr && idEtab ? onConfirm : undefined}
        buttonStyle="btn-primary"
      ></ButtonElement>
    </div>
  );
}
